import { Link } from "react-router-dom";

interface LinkButtonProps {
    to: string
    children: React.ReactNode
}

const LinkButton = ({to, children}: LinkButtonProps) => {
    return (    
        <h1 className="font-sans font-bold text-2xl text-white">
            <Link className="p-4 border-2 border-white rounded-lg hover:bg-white hover:text-gray-700" to={to}>{children}</Link>
        </h1>
    )
}

export default LinkButton;
